import React, { useState, useRef, useEffect } from 'react';
import "./CSS/colorSchemes.css";
import "./CSS/general.css"
import "./CSS/headerAndFooter.css";
import "./CSS/App.css";
import Header from './comp/Header';
import Section from './comp/Section';
import Card from './comp/Card';
import PItem from './comp/PItem';
import SkillItem from './comp/SkillItem';
import NavBtn from './comp/NavBtn';
import Hero from './comp/Hero';
import emailjs from 'emailjs-com';
import portfolioItems from "./data/portfolioData.json";
import skillItems from "./data/skillData.json";
import ComingSoon from './comp/ComingSoon';

export default function App() {
    // Constants
    const currentHour = new Date().getHours();
    const textVals = ["Cameron McCarty", "Full-Stack Developer", "Musician"];
    const pArray1 = portfolioItems.pItems;
    const pArray2 = skillItems.pItems;

    // Refs
    const refNavBar = useRef(null);
    const refSkills = useRef(null);
    const refPortfolio = useRef(null);
    const refContact = useRef(null);
    const form = useRef(null);
    const refArr = [refContact, refPortfolio];
    const refs = [refNavBar, refSkills, refPortfolio, refContact];
    
    // State Variables
    const [mode, toggleMode] = useState(currentHour >= 18 || currentHour <= 5 ? true : false);
    const [scrollBtn, setScrollBtn] = useState(false);
    const [sender_email, setSenderEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [posPortfolio, setPosPortfolio] = useState(1);
    const [posSkill, setPosSkill] = useState(0);

    // Event Listeners
    window.addEventListener("scroll", () => {
        if (window.scrollY >= window.innerHeight) {
            setScrollBtn(true);
        }
        else {
            setScrollBtn(false);
        }
    });

    // Functions
    const changePosPortfolio = (x) => {
        if (x === -1) {
            if (posPortfolio === 0) {
                setPosPortfolio(pArray1.length-1);
            }
            else setPosPortfolio(posPortfolio-1);
        }
        else setPosPortfolio((posPortfolio+1)%pArray1.length);
    };
    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current?.offsetTop,
            behavior: "smooth"
        });
    }
    const changePosSkill = (x) => {
        if (x === -1) {
            if (posSkill === 0) {
                setPosSkill(pArray2.length-1);
            }
            else setPosSkill(posSkill-1);
        }
        else setPosSkill((posSkill+1)%pArray2.length);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.init("OpSgDZ_LlyD5My5_O");
        emailjs.send("mccarty_cam_service","contact_form", {
            sender_email: sender_email,
            subject: subject,
            message: message,
        }
        ).then((response) => {
            setSenderEmail("");
            setSubject("");
            setMessage("");
            alert('Message sent!', response.status, response.text);
        }).catch((err) => {
            alert('An error occurred while trying to send your messge. Please try again later.', err.text);
        });
    }
    

    // Handlers
    const handleCTA = () => {
        scrollToSection(refSkills);
    }

    ///////////////////////////////// Start of DOM
    // if (window.innerWidth < 1200) {
    //     return (
    //         <ComingSoon />
    //     );
    // }

    return (
        // The CSS modifiers on the .App element do the following: Toggle light/darkmode; Select color scheme;
        <div className={`App ${mode ? "d" : "l"}mode CS0`}>
            
            {/* Home */}
            <Header scrollToSection={scrollToSection} mode={mode} toggleMode={toggleMode} refNavBar={refs[0]} refSkills={refs[1]} refPortfolio={refs[2]} refContact={refs[3]} />

            <Hero handleCTA={handleCTA} />
            
            <main>
                {/* Skills and Interests */}
                <Section secRef={refSkills} color={"img3"}>
                    <h4 className='w80p'>Skills and Interests</h4>
                    <div className={'pSkillGridScroll left'} onClick={()=>{changePosSkill(-1);}} />
                    <div className='skillGrid pl10 pr10 flex mt2'>
                        {pArray2.map((item, i)=> {
                            return <SkillItem aLink={item.aLink} pTitle={item.pTitle} pImg={item.pImg} pos={posSkill} index={i} key={i} zPos={pArray2.length - i} anim={item.animation}>{item.pContent}</SkillItem>;
                        })}
                    </div>
                    <div className='pSkillGridScroll right' onClick={()=>{changePosSkill(1);}} />
                </Section>
                {/* Portfolio */}
                <Section secRef={refPortfolio}>
                    <h4 className='hMin'>Portfolio</h4>
                    <div className={'pGridScroll left'} onClick={()=>{changePosPortfolio(-1);}} />
                    <div className='portfolioGrid pl10 pr10 flex mt2'>
                        {pArray1.map((item, i)=> {
                            return <PItem aLink={item.aLink} pTitle={item.pTitle} pImg={item.pImg} pos={posPortfolio} index={i} key={i} zPos={pArray1.length - i}>{item.pContent}</PItem>;
                        })}
                    </div>
                    <div className='pGridScroll right' onClick={()=>{changePosPortfolio(1);}} />
                </Section>
                {/* Contact */}
                <Section secRef={refContact} color={"img2"}>
                    <Card mods={"w60p pl5 pr5 h-centered mt1"}>
                        <h4>Contact</h4>
                        <form ref={form} className='flexWrapWrap mt3 mb2' onSubmit={sendEmail}>
                            <label className='mb2' htmlFor="sender_email"><span>Email:</span>
                                <input value={sender_email} name='sender_email' id="sender_email" type="email" onChange={(e)=>{setSenderEmail(e.target.value)}} required />
                            </label>
                            <label className='mb1' htmlFor="subject"><span>Subject:</span>
                                <input value={subject} name='subject' id="subject" type="text" onChange={(e)=>{setSubject(e.target.value)}} required />
                            </label>
                            <label className='mb2' htmlFor="message"><span>Message:</span>
                                <textarea value={message} name='message' id="message" onChange={(e)=>{setMessage(e.target.value)}} required />
                            </label>
                            <p className='h-centered flb100 tac'>I look forward to hearing from you!</p>
                            <button className='mt3' type="submit">Send</button>
                        </form>
                    </Card>
                    <div className='socialBar grid gtc3'>
                        <a className='socialBtn linkedIn' href="https://www.linkedin.com/in/cameron-m-31345728a/" target="_blank" rel="noreferrer" title='LinkedIn' />
                        <a className='socialBtn github' href="https://github.com/C-McCarty" target="_blank" rel="noreferrer" title='GitHub' />
                        <a className='socialBtn youTube' href="https://youtube.com/@emcemusic" target="_blank" rel="noreferrer" title='YouTube' />
                    </div>
                </Section>
            </main>
            <footer>
                <div className="addressWrapper">
                    <address>
                        <div className='logo'></div>Copyright &#169; 2025 - Cameron McCarty. All rights reserved.
                    </address>
                </div>
                <nav>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refSkills}>Skills</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refPortfolio}>Portfolio</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refContact}>Contact</NavBtn>
                </nav>
            </footer>
            {/* Back to top button */}
            <div className={`bttBtnWrapper${scrollBtn ? "" : " bttHidden"}`}>
                <button className="bttBtn" onClick={()=>{scrollToSection(refNavBar)}}><div /></button>
            </div>
        </div>
    );
}