import React, { useState, useRef, useEffect } from 'react';

import NavBtn from "./NavBtn";



export default function Header({scrollToSection, mode, toggleMode, refNavBar, refSkills, refPortfolio, refContact}) {
    // State
    const [modeBtnClicked, setModeBtnClicked] = useState(false);
    const [menuOpen, toggleMenu] = useState(false);
    
    // Functions
    const handleModeClick = () => {
        setModeBtnClicked(true);
        toggleMode(!mode);
    }
    const burn = () => {
        alert("Scorched Earth Policy Enabled");
        setInterval(() => {
            const t = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);
            const a = [];
            while(t.nextNode()) { a.push(t.currentNode) }
            const r = a[Math.floor(Math.random()*a.length)];
            if (r && r.textContent.trim().length > 0) {
                const o = r.textContent;
                const n = Math.floor(Math.random()*o.length);
                const e = o[n];
                if (/^[a-zA-Z0-9]$/.test(e)) {
                    const c = o.substring(0,n) + "🔥" + o.substring(n+1);
                    r.textContent=c
                }
            }
        }, 1);
    }

    return (
        <header>
            <div className='headerWrap'>
                <h1 onClick={burn}>McCarty.Cam</h1>
            </div>
            <div id='headerSpacer'></div>
            <nav ref={refNavBar} onClick={() => {toggleMenu(!menuOpen)}}>
                <NavBtn scrollToSection={scrollToSection} currRef={refSkills}>Skills</NavBtn>
                <NavBtn scrollToSection={scrollToSection} currRef={refPortfolio}>Portfolio</NavBtn>
                <NavBtn scrollToSection={scrollToSection} currRef={refContact}>Contact</NavBtn>
            </nav>
            <div className={"modeBtn" + (modeBtnClicked ? " modeBtnClicked" : "")} onClick={()=>{handleModeClick();}} onMouseLeave={()=>{setModeBtnClicked(false);}}>
                <div className="modeSwitch"><div className="modeSwitchInverter" /></div>
                <div className='modeBtnBar' />
            </div>
            {(window.innerWidth < 1140) ? <div className={menuOpen ? "menu open" : "menu closed"} onClick={() => {toggleMenu(!menuOpen)}}><div></div><div></div><div></div></div> : <></>}
        </header>
    );
}