import { useState } from "react";
import "../CSS/hero.css";
import { useEffect } from "react";

export default function Hero({handleCTA}) {
    const [isActive, toggleActive] = useState([false, false, false]);
    useEffect(() => {
        setTimeout(() => {
            toggleActive([true, false, false]);
            console.log("fired")
        }, 3000);
    }, [])
    const textToType = ['cd "Cameron McCarty"', 'cd "Full-Stack Web Developer"', "dir"]
    const [text, setText] = useState(["", "", ""]);

    useEffect(() => {
        if (isActive[0]) {
            let i = 0;
            const typeInterval1 = setInterval(() => {
                if (i < textToType[0].length) {
                    setText((prevText) => [prevText[0] + textToType[0][i-1], prevText[1], prevText[2]]);
                    i++;
                } else {
                    clearInterval(typeInterval1);
                    toggleActive([false, false, false]);
                    setTimeout(() => {
                        toggleActive([false, true, false]);
                    }, 3000);
                }
            }, (Math.floor(Math.random() * (120 - 90 + 1)) + 90));

            return () => clearInterval(typeInterval1);
        }
        if (isActive[1]) {
            let i = 0;
            const typeInterval2 = setInterval(() => {
                if (i < textToType[1].length) {
                    setText((prevText) => [prevText[0], prevText[1] + textToType[1][i-1], prevText[2]]);
                    i++;
                } else {
                    clearInterval(typeInterval2);
                    toggleActive([false, false, false]);
                    setTimeout(() => {
                        toggleActive([false, false, true]);
                    }, 2000);
                }
            }, (Math.floor(Math.random() * (120 - 90 + 1)) + 90));

            return () => clearInterval(typeInterval2);
        }
        if (isActive[2]) {
            let i = 0;
            const typeInterval2 = setInterval(() => {
                if (i < textToType[2].length) {
                    setText((prevText) => [prevText[0], prevText[1], prevText[2] + textToType[2][i-1]]);
                    i++;
                } else {
                    clearInterval(typeInterval2);
                    toggleActive([false, false, false]);
                    // setTimeout(() => {
                    //     toggleActive([false, false, false]);
                    // }, 3000);
                }
            }, (Math.floor(Math.random() * (120 - 90 + 1)) + 90));

            return () => clearInterval(typeInterval2);
        }
    }, [isActive]);

    return (
        <div id="hero">
            {(window.innerWidth < 1140) ? <div id="mHeroText"><h2>Cameron McCarty</h2><h2>Full-Stack Web Dev</h2></div> : <>
                <div className="heroImgBkg">
                    <div className="pfp"></div>
                </div>
                <div className="typeBoxContainer">
                    <div id="typeBoxTitleBar"><div className="cmdIcon"></div><span className="title">Command prompt</span></div>
                    <div id="typeArea">
                        <div className='typeBox'>
                            <span className="gt">C:\&gt;</span>
                            <span className="typeText">{text[0]}</span>
                            <span className={text[0].length > 0 ? (isActive[0] ? "caret active" : "caret end") : "caret"}>|</span>
                        </div>
                        <div className='typeBox'>
                            <span className={text[0] == textToType[0] ? "gt" : "gt hidden"}>C:\Cameron McCarty&gt;</span>
                            <span className="typeText">{text[1]}</span>
                            <span className={text[0] == textToType[0] ? (text[1].length > 0 ? (isActive[1] ? "caret active" : "caret end") : "caret") : "caret end"}>|</span>
                        </div>
                        <div className='typeBox'>
                            <span className={text[1] == textToType[1] ? "gt" : "gt hidden"}>C:\Cameron McCarty\Full-Stack Web Developer&gt;</span>
                            <span className="typeText">{text[2]}</span>
                            <span className={text[1] == textToType[1] ? (text[2].length > 0 ? (isActive[2] ? "caret active" : "caret end") : "caret") : "caret end"}>|</span>
                        </div>
                        <div id="dirText" className={text[2] == textToType[2] ? "visible" : "hidden"}>
                            <pre> AAS in Web Development</pre>
                            <pre> BS in Information Technology (Expected Summer 2025)</pre><br/>
                            <pre>Skills of Cameron McCarty</pre><br/>
                            <pre>PRGMG - Programming</pre>
                            <pre>SKILL - Skillset</pre>
                            <pre>LANG  - Language</pre> <br />
                            <pre>4 years    &lt;LANG&gt;     ReactJS</pre>
                            <pre>4 years    &lt;LANG&gt;     HTML5</pre>
                            <pre>4 years    &lt;LANG&gt;     CSS</pre>
                            <pre>4 years    &lt;LANG&gt;     JavaScript</pre>
                            <pre>3 years    &lt;LANG&gt;     JSON</pre>
                            <pre>2 years    &lt;LANG&gt;     Python</pre>
                            <pre>2 years    &lt;LANG&gt;     SQL</pre>
                            <pre>2 years    &lt;LANG&gt;     Java</pre>
                            <pre>1 year     &lt;LANG&gt;     Node.js</pre>
                            <pre>1 year     &lt;LANG&gt;     Express.js</pre>
                            <pre>1 year     &lt;LANG&gt;     MySQL</pre>
                            <pre>1 year     &lt;LANG&gt;     C#</pre>
                            <pre>1 year     &lt;LANG&gt;     C++</pre>
                            <pre>3 years    &lt;PRGMG&gt;    GitHub</pre>
                            <pre>3 years    &lt;PRGMG&gt;    Git</pre>
                            <pre>2 years    &lt;PRGMG&gt;    APIs</pre>
                            <pre>2 years    &lt;PRGMG&gt;    Microsoft SQL Server</pre>
                            <pre>1 year     &lt;PRGMG&gt;    .NET</pre>
                            <pre>1 year     &lt;PRGMG&gt;    MongoDB</pre>
                            <pre>1 year     &lt;PRGMG&gt;    WordPress</pre>
                            <pre>1 year     &lt;PRGMG&gt;    Bootstrap</pre>
                            <pre>5 years    &lt;SKILL&gt;    Adobe Photoshop</pre>
                            <pre>4 years    &lt;SKILL&gt;    UI and UX design</pre>
                            <pre>4 years    &lt;SKILL&gt;    E-commerce</pre>
                            <pre>4 years    &lt;SKILL&gt;    Front-end development</pre>
                            <pre>4 years    &lt;SKILL&gt;    Layout design</pre>
                            <pre>4 years    &lt;SKILL&gt;    Computer science</pre>
                            <pre>4 years    &lt;SKILL&gt;    Leadership</pre>
                            <pre>3 years    &lt;SKILL&gt;    Training and development</pre>
                            <pre>3 years    &lt;SKILL&gt;    Backend development</pre>
                            <pre>3 years    &lt;SKILL&gt;    Full-stack development</pre>
                            <pre>3 years    &lt;SKILL&gt;    Database management</pre>
                            <pre>3 years    &lt;SKILL&gt;    Object-Oriented Programming</pre>
                            <pre>2 years    &lt;SKILL&gt;    Content management systems</pre>
                            <pre>2 years    &lt;SKILL&gt;    Customer service</pre>
                            <pre>2 years    &lt;SKILL&gt;    IT Support</pre>
                            <pre>2 years    &lt;SKILL&gt;    Agile</pre>
                            <pre>1 year     &lt;SKILL&gt;    PostgreSQL</pre>
                        </div>
                        <div className='typeBox'>
                            <span className={text[2] == textToType[2] ? "gt" : "gt hidden"}>C:\Cameron McCarty\Full-Stack Web Developer&gt;</span>
                            <span className={text[2] == textToType[2] ? (isActive[2] ? "caret active" : "caret") : "caret end"}>|</span>
                        </div>
                    </div>
                </div>
            </>}
            <div className="ctaBtnWrap">
                <div className="ctaBtn" onClick={handleCTA}></div>
            </div>
        </div>
    );
}